import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Image from 'components/help/helpImage';

import intercom1 from 'img/help/intercom/intercom-integration1.png';
import intercom2 from 'img/help/intercom/intercom-integration2.png';
import intercom3 from 'img/help/intercom/intercom-integration3.png';
import intercom4 from 'img/help/intercom/intercom-integration4.png';
import intercom5 from 'img/help/intercom/intercom-integration5.png';
import intercom6 from 'img/help/intercom/intercom-integration6.png';
import intercom7 from 'img/help/intercom/intercom-integration7.png';
import intercom8 from 'img/help/intercom/intercom-integration8.png';
import logo from 'img/help/integrations/intercom.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Intercom?</SubTitle>
      <p>
        Intercom is a <strong>live chat solution</strong> for support, sales and marketing teams. It
        allows you to track events and see how the customers interact with your product.
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        The LiveSession integration adds a recording link (as a note) to every Intercom conversation
        with an identified user. This way, you can get more insights and see exactly what the user
        sees.
      </p>
      <Image src={intercom1} lazy alt="Intercom integration" title="Conversation" />
      <p>
        Every time a new Intercom conversation starts, a note with a link to the session recording
        is created. You can play recordings of a specific user directly from the Intercom
        conversation.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <p>
          For the integration to work, you need to have both Intercom and LiveSession installed on
          your website. If you haven&apos;t installed our tracking code yet, check{' '}
          <a
            href="/help/how-to-install-livesession-script/"
            target="_blank"
            rel="noopener noreferrer"
          >
            this article
          </a>{' '}
          for instructions.
        </p>
      </div>
      <p>
        To install Intercom integration go to <strong>Settings &gt; Integrations</strong> and click
        Install:
      </p>
      <Image
        src={intercom2}
        lazy
        alt="Intercom integration"
        title="Integrations page in LiveSession app"
      />
      <p>
        Click <strong>Install</strong> to confirm:
      </p>
      <Image
        src={intercom3}
        lazy
        alt="Intercom integration"
        title="Click on Intercom and install"
      />
      <p>
        You will be redirected to Intercom. Authorize <strong>LiveSession</strong> access to your{' '}
        <strong>Intercom</strong> workspace:
      </p>
      <Image src={intercom4} lazy alt="Intercom integration" title="Authorize access" />
      <p>
        After granting access, you will be redirected back to <strong>LiveSession</strong>. You’ll
        see the Intercom integration on the list:
      </p>
      <Image
        src={intercom5}
        lazy
        alt="Intercom integration"
        title="Integration confirmed in LiveSession app"
      />
      <p>To make the integration work, you also need to:</p>
      <ul>
        <li>
          Use the <Code inline>Intercom(‘boot’)</Code> or <Code inline>Intercom(‘update’)</Code>{' '}
          command with the user’s email value. If you need help, you can find instructions in
          Intercom{' '}
          <a
            href="https://developers.intercom.com/installing-intercom/docs/intercom-javascript"
            target="_blank"
            rel="noopener noreferrer"
          >
            docs
          </a>
          .
        </li>
        <li>
          Use the same email address to identify the user in LiveSession with the{' '}
          <Code inline>__ls(“identify”)</Code> method. If you’re not sure how to do it, you can read
          a tutorial{' '}
          <a
            href="/help/user-identification-custom-properties/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </li>
      </ul>
      <SubTitle className="mt-5">Additional settings</SubTitle>
      <p>You can also configure these options (enabled by default):</p>
      <p> LiveSession recording URL in user panel as an attribute:</p>
      <Image src={intercom6} lazy alt="Intercom integration" title="LiveSession URL" />
      <p> LiveSession recording URL as a user’s event:</p>
      <Image src={intercom7} lazy alt="Intercom integration" title="Recent events" />
      <p>
        These can be changed in integration{' '}
        <a
          href="https://app.livesession.io/settings/integrations?integration=intercom"
          target="_blank"
          rel="noopener noreferrer"
        >
          settings
        </a>
        .
      </p>
      <Image src={intercom8} lazy alt="Intercom integration" title="Settings in LiveSession app" />
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Intercom integration',
  metaDescription: 'Send LiveSession recordings to your Intercom account',
  canonical: '/help/intercom-integration/',
  integrationLink: '/app/install/intercom',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
